import React from "react";
import ComingSoon from "../components/ComingSoon";
import Article from "./Article";
import "./Motoring.css";

const Motoring = (props) => {
  return (
    <>
      <div className="current-fleet">
        <div className="fleet-heading">Current Fleet:</div>
        <div className="vehicle">1992 Eunos Roadster 1.6</div>
        <div className="vehicle">2005 Mini Cooper </div>
        <div className="fleet-heading">Next Daily?</div>
        <div className="vehicle">Volvo C30</div>
        <div className="vehicle">BMW E82 1 Series Coupe</div>
        <div className="fleet-heading">Dream 2 car garage:</div>
        <div className="vehicle">Mazda RX-7 FB</div>
        <div className="vehicle">Saab 900 Turbo</div>
        <div className="fleet-heading">Future Classic Predictions:</div>
        <div className="vehicle">K11 Micra</div>
        <div className="vehicle">Alfa Romeo Brera</div>
      </div>
      <div className="Article-grid">
        <Article
          title="1992 Mazda Eunos Roadster"
          subtitle="Is it the best enthusiast car for young drivers?"
          paragraphs={[
            "Anybody with a hankering for a small, lightweight, rear-drive sports car would consider the MX-5, but should you consider the MK1 (NA) MX-5 as your first enthusiast car purchase?",
            "Starting production in 1989, the first generation of Mazda MX-5 is almost 35 years old, making the MX-5 qualify as a classic car and eligible for classic car insurance. Currently, you can expect to pay anywhere between £2000 - £8000 for a roadworthy example, depending on condition, mileage, and specification. Rust-Free Mid-Mileage examples begin at around £4000.",
            "Let's begin with why you should choose this vehicle. The driving experience. As soon as you prepare to drive away, the wonderfully short journey from neutral into first and the immediate throttle response from the accelerator pedal encourages you to embrace such pure feel and feedback from the car. Once moving, the 1.6 litre 4 cylinder engine is so eager and quick to rev, redlining at 7200rpm. Taking around 9.5 seconds from 0-60, the 115bhp engine powering the car does not appear to be fast enough to excite most petrol heads but flooring the accelerator, both on straight and winding roads, has induced such satisfaction throughout my experience. These cars are loved world-wide for their agility. Boasting a 50/50 weight distribution, rear wheel drive, and a double wishbone suspension setup, it is clear that the MX-5 was for drivers who love to indulge in driving.",
            "As a young driver, I believe the NA (and potentially NB) generation of Mazda MX-5 is a perfect platform to start as a driving enthusiast. The car requires sensible pedal discipline and respect, as it teaches the enthusiast behind the wheel how to drive a car properly and when the right moment to brake, accelerate, lift-off the throttle and so on. I feel there are so many drivers on the road that would benefit from the school of the MX-5, becoming more confident and sensible drivers after their experience.",
            "In my ownership of my 1992 Eunos Roadster, I have driven ",
          ]}
          area="a"
          type="review"
          length="medium"
          date="21/03/2023"
        ></Article>
        <Article
          paragraphs={[1, 2, 3]}
          title="Interesting First Car"
          subtitle="What to consider as a first car if you have a passion for cars"
          preview=""
          area="b"
          type="discussion"
        ></Article>
        <Article
          title="R50 Mini - Daily Driver Diary"
          subtitle="Why the R50 shaped BMW-Mini is my go-to recommendation for cheap and enjoyable motoring"
          preview=""
          area="c"
          type="diary"
        ></Article>
        <Article
          title="Suzuki Swift GLS - Daily Driver Diary"
          subtitle="The tale of my introduction to motoring - an old econo-box left to rot"
          paragraphs={[
            "The story of how my first car evolved from it's role as a makeshift shed growing mold in a car park, to a cherished and appreciated daily driver for 3 years.",
          ]}
          area="d"
          type="diary"
        ></Article>
        <Article title="5 Cars I want to drive before they disappear" subtitle="" preview="" area="e"></Article>
        <Article title="" subtitle="" preview="" area="f"></Article>
        {/* <Article title="" subtitle="" preview="" area="g"></Article>
        <Article title="" subtitle="" preview="" area="h"></Article> */}
      </div>
    </>
  );
};

export default Motoring;
