import React, { useState } from "react";
import Modal from "../components/Modal";
import "./Article.css";

const Article = (props) => {
  const [showModal, setShowModal] = useState(false);
  const showModalHandler = function () {
    setShowModal(true);
  };
  const closeModalHandler = function () {
    setShowModal(false);
  };
  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          heading={props.title}
          subtitle={props.subtitle}
          content={props}
          onClose={closeModalHandler}
        ></Modal>
      )}
      <div className={`article`} style={{ gridArea: props.area }} onClick={showModalHandler}>
        <div className="title-section">
          <div className="article-title">{props.title}</div>
          <div className="article-sub-title">{props?.subtitle}</div>
        </div>
        <div className="preview-section">
          <div className="preview-text">{props?.paragraphs && props.paragraphs[0]}</div>
        </div>
        <div className="bottom-section">
          <div className={`article-type ${props.type}`}>{props.type}</div>
          <div className={`article-date ${props.date}`}>
            {" "}
            <span className="material-icons" style={{}}>
              calendar_month
            </span>
            {props.date}
          </div>
          <div className={`article-length `}>
            {" "}
            <span className="material-icons">auto_stories</span> {props.length}
          </div>
          <div className="click-for-more">Click to continue reading</div>
        </div>
      </div>
    </>
  );
};

export default Article;
