import React from "react";
import "./Modal.css";
import "../motoring/Article.css";

const Modal = function (props) {
  const modalClass = props.show ? "show-modal" : "hide-modal";

  return (
    <div className={modalClass + ` modal`}>
      <div className="modal-window">
        <h2 className="modal-heading">{props.heading}</h2>
        <h2 className="modal-sub-title">{props.subtitle}</h2>
        <div className="text-section">
          {props?.content?.paragraphs?.map((p) => {
            return <div className="article-paragraph">{p}</div>;
          })}
          {console.log(props)}
        </div>
        <div className="close-modal-button material-icons" color="red" value="Back" onClick={props.onClose}>
          close
        </div>
      </div>
    </div>
  );
};

export default Modal;
